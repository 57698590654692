<template>
    <div class="wrapper">
        <img class="tu" src="https://static.yihu365.cn/img/h5Img/assets/img/banner.10d24720.png" alt="">
        <div class="box">
            <div class='login_cont'>
                <div class='form_cont'>
                    <van-field v-model="tel" placeholder="输入手机号" class='tel_input' clearable type='number'/>
                </div>
                <div class='code_cont'>
                    <van-field v-model="code" placeholder="输入验证码" class='tel_input' maxlength="6" @input='inputChange'/>
                    <div class='send_code' @click='send_code'>{{isSendCodeFlag ? reSendSecond + 's' : '获取验证码'}}</div>
                </div>
<!--                <div class='form_cont'>-->
<!--                    <van-field v-model="password" placeholder="设置密码" class='tel_input' clearable type='password'/>-->
<!--                </div>-->
                <van-button type="success" color='#FFB305' class='submit_btn' round block :disabled='!tel || !code'
                            @click='nurseBind'>注册
                </van-button>
                <div class="txt">注：初始密码为123456</div>
            </div>
        </div>
    <van-dialog v-model:show="codeShow" show-cancel-button confirmButtonColor='#00C291' confirmButtonText='确认'
                @confirm='validation_code' @cancel='codeShow = false' :before-close='beforeClose'>
        <div class='reson'>获取验证码</div>
        <div class='reson_tips'>请输入图片中出现的人机识别码</div>
        <div class='img_cont'>
            <img :src='code_img'>
            <van-icon name="replay" @click='send_code'/>
        </div>
        <div class='pay_input'>
            <van-field v-model="check_calculate" placeholder="请输入结果" type='number'/>
        </div>
    </van-dialog>
    <div class='tips fixb' v-if='store.state.channel != "1"'>{{channel[store.state.channel].bottm}}</div>

    </div>
</template>

<script>
    import axios from 'axios'
    import {onMounted, ref, computed} from "vue"
    import {useRouter, useRoute} from 'vue-router'
    import {Store, useStore} from 'vuex'
    import {Toast} from 'vant'
    import loginApi from '@axios/login'
    import {channel} from '@/config/channel.js';

    const TIME_COUNT = 60

    export default {
        name: 'two',
        components: {},
        setup() {
            let router = useRouter()
            const store = useStore()
            const route = useRoute()
            let tel_reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
            let codeShow = ref(false);
            let code_img = ref('');
            let code_key = ref('');
            let openId = ref('');
            let tel = ref('');
            // let password = ref('');
            let code = ref('');
            let check_calculate = ref('');
            let isSendCodeFlag = ref(false);
            let reSendSecond = ref(TIME_COUNT);
            let verifyId = '';

            // !route.query.code
            //如果没有code 获取code
            // if(!store.state.openId){
            //     if (!route.query.code) {
            //         let params = {
            //             redirect_uri: location.origin + '/loginNurse'
            //         }
            //         loginApi.getCode(params).then((res) => {
            //             window.location.href = res.data;
            //         }).catch((e) => {
            //             if (e.data) {
            //                 window.location.href = e.data
            //             }
            //         })
            //     } else {
            //         let params = {
            //             code: route.query.code
            //         }
            //         loginApi.getOpenId(params).then((res) => {
            //             openId.value = res.data.openid
            //             store.commit('setOpenId',res.data.openid);
            //             getNurseByOpenId(openId.value);
            //         }).catch((e) => {
            //
            //         })
            //     }
            // }

            //查询是否绑定
            // const getNurseByOpenId = (openid) => {
            //     let params = {
            //         openId: openid
            //     }
            //     loginApi.getBindState(params).then((res) => {
            //             let status = res.data.status;
            //             if (status == 1) {//已经绑定
            //                 router.push({path:'/bindSuccess',query:{type:1}});
            //             } else {//未绑定
            //                 Toast("用户未绑定,请绑定");
            //             }
            //         }
            //     ).catch((e) => {
            //
            //     })
            // }

            const send_code = () => {
                if (!tel.value || !tel_reg.test(tel.value)) {
                    Toast('请输入正确手机号');
                    return;
                }
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: 'getVerify',
                    source : 'h5_users_002'
                }))
                formData.append('encryption', false)


                if (isSendCodeFlag.value) {
                    return null
                }
                check_calculate.value = ''

                loginApi.loginAction(formData).then((res) => {
                    codeShow.value = true;
                    code_img.value = res.data.imgUrl;
                    code_key.value = res.data.key

                })
            }

            const inputChange = (value) => {
                if (code.value.length == 6) {
                    nurseBind()
                }
            }

            let interval;

            const validation_code = () => {
                if (!check_calculate.value) {
                    Toast('请输入图片结果');
                    return
                }

                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: 'checkVerify',
                    yzmResult: check_calculate.value,
                    key: code_key.value,
                }))
                formData.append('encryption', false)
                isSendCodeFlag.value = true
                loginApi.loginAction(formData).then((res) => {
                    codeShow.value = false;

                    send_code_action();
                }).catch(res => {
                    clearInterval(interval)
                    reSendSecond.value = TIME_COUNT
                    isSendCodeFlag.value = false
                })
            }


            const send_code_action = () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                     source: "android_health_101",
                    sid: "90001100111",
                    _lan: "40.027112",
                    _lon: "116.472177",
                    token: "",
                    timeStamp: "2719924",
                    function: "authcodesend",
                    version: "2.100",
                    mac: "IMSI_73d4582cecbe3b88",
                    type: "0",
                    usermobile: tel.value,
                    _cityCode: "131",
                    sendtype: "0",
                    login_userId_base: "",
                    newversion: "2100"
                }))
                formData.append('encryption', false)
                console.log(formData)
                // axios.get('http://appyhdj.yihu365.cn/NurseHomeControl.action',{params:formData}).then(()=>{
                 loginApi.loginAction(formData).then((res) => {
                    console.log(res);
                    verifyId = res.codeid;

                    interval = setInterval(() => {
                        reSendSecond.value--;
                        if (reSendSecond.value === 0) {
                            isSendCodeFlag.value = false
                            reSendSecond.value = TIME_COUNT
                            clearInterval(interval)
                        }
                    }, 1000)

                }).catch(res => {
                    console.log(res)
                    clearInterval(interval)
                    reSendSecond.value = TIME_COUNT
                    isSendCodeFlag.value = false
                })
            }


            const beforeClose = () => {
                return false;
            }

            //http://appyhdj.yihu365.cn/NurseHome_Control.action
            const nurseBind = () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    verifyId: verifyId,
                    password: "123456",
                    mac: "IMSI_73d4582cecbe3b88",
                    version: "2.100",
                    sid: "90001100111",
                    function: "registerNew",
                    // Recommenduserphone: "15230091402",
                    // recommendId:"6922976",//推荐人id
                    recommendId:route.query.userId,//推荐人id
                    token: "",
                    _lon: "116.472184",
                    timeStamp: "2719936",
                    usermobile:tel.value ,
                    phoneModel: "SEA-AL10",
                    login_userId_base: route.query.userId,
                    _cityCode: "131",
                    phoneSystemVersion: "10_emuiApiLevel27_hwid60100306",
                    _lan: "40.027116",
                    userType: "1",
                    source: "android_health_101",
                    phoneBrand: "huawei",
                    newversion: "2100",
                    verifyCode: code.value
                }))
                formData.append('encryption', false)

                loginApi.loginAction(formData).then((res) => {
                    Toast(res.message);
                    if(res.code == '0000'){
                        router.push({
                            name:'downloadNurse'
                        })
                    }
                })


            }


            onMounted(()=>{
                window.addEventListener('pagehide',()=>{
                    window.localStorage.removeItem('openId');
                })
            })


            return {
                channel,
                store,
                inputChange,
                nurseBind,
                beforeClose,
                validation_code,
                reSendSecond,
                isSendCodeFlag,
                check_calculate,
                code,
                tel,
                openId,
                code_img,
                send_code,
                router,
                codeShow
            }
        },

    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
   .wrapper{
       .tu{
           width: 100%;
       }
       background-color: #893dff;
   }
   .fixb {
       position: fixed;
       bottom: 100px;
       width: 100%;
       text-align: center;
   }

   .img_cont {
       display: flex;
       justify-content: center;
       align-items: center;
       margin: 20px 0;

       img {
           margin-right: 20px;
       }
   }

   .reson_tips {
       font-size: 28px;
       font-family: PingFangSC-Regular, PingFang SC;
       font-weight: 400;
       color: #666666;
       text-align: center;
   }

   .reson {
       padding: 50px 40px;
       padding-bottom: 20px;
       text-align: center;
       font-size: 36px;
       font-family: PingFangSC-Regular, PingFang SC;
       font-weight: 400;
       color: #313131;
   }

   .pay_input {
       display: flex;
       justify-content: flex-start;
       align-items: center;
       padding: 0 40px;
       font-size: 44px;
       font-family: Gotham-Bold, Gotham;
       font-weight: bold;
       color: #313131;
       padding-bottom: 30px;
       border-bottom: 1px solid #EDEDED;
       text-align: center;

       ::v-deep(input) {
           text-align: center;
       }
   }
   .box{
       background-color: #893dff;
       padding:20px 40px 180px 40px;
   }

   .login_cont {
       .txt{
           margin-top: 16px;
           text-align: center;
           width: 100%;
           font-size: 26px;
           color:#CDCDCD;
       }
       border-radius: 16px;
       padding:40px;
       background-color:#fff;
       .login_title {
           font-size: 50px;
           font-family: PingFangSC-Semibold, PingFang SC;
           font-weight: 600;
           color: #313131;
           padding-left: 20px;
       }

       ::v-deep(.van-cell) {
           padding: 0;

       }

       .form_cont {
           .tel_input {
               /*margin-top: 20px;*/
               font-size: 30px;
               font-family: PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               border-bottom: 1px solid #CDCDCD;
               padding: 16px 0;
               padding-left: 20px;
           }
       }

   }

   .code_cont {
       .tel_input {
           /*margin-top: 10px;*/
           font-size: 30px;
           font-family: PingFangSC-Regular, PingFang SC;
           font-weight: 400;
           border-bottom: 1px solid #CDCDCD;
           padding: 20px 0;
           padding-left: 20px;
       }

       position: relative;

       .send_code {
           position: absolute;
           right: 0;
           top: 25px;
           padding: 0 20px;
           border-left: 1px solid #CDCDCD;
           font-size: 28px;
           font-family: PingFangSC-Medium, PingFang SC;
           font-weight: 500;
           /*color: #00C291;*/
           color: #CDCDCD;
           min-width: 100px;
           text-align: center;
       }
   }

   .submit_btn {
       margin-top: 60px;
   }
</style>
