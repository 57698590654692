import { render } from "./two.vue?vue&type=template&id=7a1bd603&scoped=true"
import script from "./two.vue?vue&type=script&lang=js"
export * from "./two.vue?vue&type=script&lang=js"

import "./two.vue?vue&type=style&index=0&id=7a1bd603&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7a1bd603"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7a1bd603"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7a1bd603', script)) {
    api.reload('7a1bd603', script)
  }
  
  module.hot.accept("./two.vue?vue&type=template&id=7a1bd603&scoped=true", () => {
    api.rerender('7a1bd603', render)
  })

}

script.__file = "src/views/static/about/two.vue"

export default script